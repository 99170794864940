import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import ui from '@alpinejs/ui';
import anchor from '@alpinejs/anchor'
import * as Sentry from "@sentry/browser";
import '@github/relative-time-element';
import '@nextapps-be/livewire-sortablejs';
import '../../vendor/wire-elements/pro/resources/js/overlay-component.js'

import.meta.glob([
  '../images/**',
]);

Alpine.plugin(ui);
Alpine.plugin(anchor);

Sentry.init({
    dsn: window.sentry_dsn,
    release: window.sentry_release,
    environment: window.sentry_environment,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: window.sentry_traces_sample_rate,
});

Livewire.start();
